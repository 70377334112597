import smoothscroll from "smoothscroll-polyfill";
import "./navigation.css";

// kick off the polyfill!
smoothscroll.polyfill();

const icon = document.querySelector(".icon");
const iconCross = document.querySelector(".icon-cross");
const container = document.querySelector(".nav-text-container");
const scrollToTop = document.querySelector(".nav-toTop-container");
//const nav = document.getElementsByTagName("nav")[0];
const cubeLogo = document.querySelector(".dpd-cube");
const title = document.querySelector(".dpd-title");

const anchors = [...document.querySelectorAll(".nav-text-container > a")];
anchors.forEach((element) => {
  var classname = element.className;

  const url = window.location.href.split("/");

  if (url[url.length - 1] === classname) {
    element.style.color = "#ffffff";
  } else {
    element.style.color = "#808285";
  }
});

cubeLogo.addEventListener("click", () => (window.location.href = "/"));
title.addEventListener("click", () => (window.location.href = "/"));

scrollToTop.addEventListener("click", () => {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
});

const resetResponsive = () => {
  container.className = "nav-text-container";
  iconCross.className = "icon-cross";
  icon.className = "icon";
};

const addResponive = () => {
  container.className += " responsive";
  iconCross.className += " responsive";
  icon.className += " responsive";
};

//add responive classname to the nav options
icon.addEventListener("click", () =>
  container.className === "nav-text-container"
    ? addResponive()
    : resetResponsive()
);

iconCross.addEventListener("click", () => resetResponsive());

window.onscroll = () => {
  if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
    scrollToTop.style.display = "block";
    // nav.style.background = "rgba(20, 23, 24, 0.7)";
    if (window.innerWidth > 1035) {
      // nav.style.height = "60px";
      // cubeLogo.style.height = "34px";
      // cubeLogo.style.width = "28px";
    }
  } else {
    if (window.innerWidth > 1035) {
      // nav.style.height = "120px";
      // cubeLogo.style.height = "44px";
      // cubeLogo.style.width = "36px";
    }
    scrollToTop.style.display = "none";
    // nav.style.background = "rgba(20, 23, 24, 0.1)";
  }
};

anchors.map((anchor) =>
  anchor.addEventListener("click", () => resetResponsive())
);
