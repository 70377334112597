import "./scene-4.css";
import TextScramble from "../../textScramble";
import { isInViewport } from "../../inView";

const text = [...document.querySelectorAll(".scramble")][2];
const text2 = [...document.querySelectorAll(".scramble")][1];

const phrase1 = [
  "it's collections",
  "made easy",
  "Collections Redesigned",
  "end",
];

const phrase2 = [
  "never miss a",
  "parcel delivery again",
  "The DPD app V2.0",
  "end",
];

const fx = new TextScramble(text);
const fx2 = new TextScramble(text2);

let counter1 = 0;
function animeate1() {
  if (phrase1[counter1] === "end") return;

  fx.setText(phrase1[counter1]).then(() => {
    setTimeout(animeate1, 200);
  });
  counter1 = (counter1 + 1) % phrase1.length;
}

let counter2 = 0;
function animeate2() {
  if (phrase2[counter2] === "end") return;

  fx2.setText(phrase2[counter2]).then(() => {
    setTimeout(animeate2, 200);
  });
  counter2 = (counter2 + 1) % phrase2.length;
}

function scene4Animate1() {
  animeate1();
  // eslint-disable-next-line
  scene4Animate1 = function empty() {};
}

function scene4Animate2() {
  animeate2();
  // eslint-disable-next-line
  scene4Animate2 = function empty() {};
}

document.addEventListener("scroll", () => {
  if (isInViewport(text)) {
    scene4Animate1();
  }
  if (isInViewport(text2)) {
    scene4Animate2();
  }
});
