import Analytics from "analytics";

const url = process.env.REACT_APP_URL;

const logger = {
  name: "innovationsitesLogger",
  page: ({ payload }) => {
    addEvent(payload);
  },
};

const analytics = Analytics({
  app: "innovationsites",
  plugins: [logger],
});

const addEvent = async (event) => {
  const context = {
    url: window.location.href,
    userAgent: navigator.userAgent,
    referrer: document.referrer,
  };

  event.properties.context = context;

  const eventAction = { actionEvents: [JSON.stringify(event)] };

  const blob = new Blob([JSON.stringify(eventAction, null, 2)], {
    type: "application/json",
  });

  navigator.sendBeacon(url, blob);
};

window.onload = function () {
  analytics.page();
};
