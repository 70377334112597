import "./scene-5.css";
import "./scene-5-2.css";
import TextScramble from "../../textScramble";
import { isInViewport } from "../../inView";

const text = [...document.querySelectorAll(".scramble")][3];
const text2 = [...document.querySelectorAll(".scramble")][4];
const checkItOut = document.querySelector(".scene-5-text");

checkItOut.addEventListener("click", () => (window.location.href = "/relove"));

const phrase1 = [
  "passionate about",
  "all things green",
  "Clean Green Delivery",
  "end",
];

const phrase2 = [
  "donate your pre-loved",
  "clothing to charity",
  "ReLOVE",
  "end",
];

const fx = new TextScramble(text);
const fx2 = new TextScramble(text2);

let counter1 = 0;
function animeate1() {
  if (phrase1[counter1] === "end") return;

  fx.setText(phrase1[counter1]).then(() => {
    setTimeout(animeate1, 200);
  });
  counter1 = (counter1 + 1) % phrase1.length;
}

let counter2 = 0;
function animeate2() {
  if (phrase2[counter2] === "end") return;

  fx2.setText(phrase2[counter2]).then(() => {
    setTimeout(animeate2, 200);
  });
  counter2 = (counter2 + 1) % phrase2.length;
}

function scene5Animate1() {
  animeate1();
  // eslint-disable-next-line
  scene5Animate1 = function empty() {};
}

function scene5Animate2() {
  animeate2();
  // eslint-disable-next-line
  scene5Animate2 = function empty() {};
}

document.addEventListener("scroll", () => {
  if (isInViewport(text)) {
    scene5Animate1();
  }
  if (isInViewport(text2)) {
    scene5Animate2();
  }
});
