import "./scene-6.css";
import TextScramble from "../../textScramble";
import { isInViewport } from "../../inView";

const text = [...document.querySelectorAll(".scramble")][5];

const phrase1 = [
  "game changers",
  "industry leaders",
  "Innovation, it's what we do",
  "end",
];

const fx = new TextScramble(text);

let counter1 = 0;
function animeate1() {
  if (phrase1[counter1] === "end") return;

  fx.setText(phrase1[counter1]).then(() => {
    setTimeout(animeate1, 200);
  });
  counter1 = (counter1 + 1) % phrase1.length;
}

function scene6Animate() {
  animeate1();
  // eslint-disable-next-line
  scene6Animate = function empty() {};
}

document.addEventListener("scroll", () => {
  if (isInViewport(text)) {
    scene6Animate();
  }
});
