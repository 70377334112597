import "./scene-3.css";
import TextScramble from "../../textScramble";
import { isInViewport } from "../../inView";

const text = [...document.querySelectorAll(".scramble")][0];

const phrases = [
  "the future of",
  "parcel tracking",
  "Follow my parcel series 2020",
  "end",
];

const fx = new TextScramble(text);

let counter = 0;
function next() {
  if (phrases[counter] === "end") return;

  fx.setText(phrases[counter]).then(() => {
    setTimeout(next, 200);
  });
  counter = (counter + 1) % phrases.length;
}

function scene3Animate() {
  next();
  // eslint-disable-next-line
  scene3Animate = function empty() {};
}

document.addEventListener("scroll", () => {
  if (isInViewport(text)) {
    scene3Animate();
  }
});
